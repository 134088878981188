import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const NavBar = ({ toggleNavbar, isActive }) => (
  <StaticQuery
    query={graphql`
      query NavbarQuery {
        fileName: file(
          relativePath: {
            eq: "logo-elektro-bechert_1000px.jpg"
          }
        ) {
          childImageSharp {
            fixed(width: 100, height: 72) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => (
      <nav className='navbar is-fixed-top' aria-label='main navigation'>
        <div className='navbar-brand'>
          <Link to='/' className='navbar-item logo'>
            <Img fixed={data.fileName.childImageSharp.fixed} alt='' />
          </Link>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 10' preserveAspectRatio='none'>
            <polygon points='100 0 100 10 0 10' />
          </svg>
          <div className='mobile-navbar-brand'>
            <button
              className={`button navbar-burger ${isActive ? 'is-active' : ''}`}
              data-target='navMenu'
              onClick={toggleNavbar}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
        </div>
        <div
          className={`navbar-menu ${isActive ? 'is-active' : ''}`}
          id='navMenu'
        >
          <div className='claim-wrapper'>
            <p className='navbar-claim extra-light'>Elektro • Beleuchtung • Kraft • Wärme • Datennetze</p>
          </div>
          <div className='navbar-end'>
            <Link className='navbar-item' activeClassName='active' to='/'>
              Home ∨
            </Link>
            <Link className='navbar-item' activeClassName='active' to='/about'>
              Unternehmen ∨
            </Link>
            <Link className='navbar-item' activeClassName='active' to='/leistungen'>
              Leistungen ∨
            </Link>
            <Link className='navbar-item' activeClassName='active' to='/referenzen'>
              Referenzen ∨
            </Link>
            <Link className='navbar-item' activeClassName='active' to='/contact'>
              Kontakt ∨
            </Link>
          </div>
        </div>
      </nav>
    )}
  />
)

export default NavBar

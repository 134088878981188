import React from 'react'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='content'>
          <p className='emergency-sign'>
            <span className='light yellow-text'>24-Stunden-Notdienst</span><br />
            <span className='yellow-text'>0177 / 21 44 809</span>
          </p>
          <p>
            <span className='bold'>Elektro-Bechert GmbH</span><br />
            Hubertusdamm 14 · 13125 Berlin + Schönerlinder Chaussee 2 · 16348 Wandlitz<br />
            Telefon 030 / 44 95 479 + 030 / 44 83 971 · Telefax 030 / 44 43 28 03<br />
            <span className='bold'><a href='mailto:info@elektro-bechert.de'>info@elektro-bechert.de</a></span>
          </p>
        </div>
        <div className='footer-nav navbar'>
          <div className='navbar-end'>
            <Link className='navbar-item' activeClassName='active' to='/impressum'>
              Impressum ∨
            </Link>
            <Link className='navbar-item' activeClassName='active' to='/datenschutz'>
              Datenschutz ∨
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
